import { useEffect, useState } from "react"
import { QuestionWithAnswers } from "../components/QuestionWithAnswers"
import { Api } from "../api/Api"
import { useNavigate, useParams } from "react-router-dom"
import { HttpStatusCode } from "axios"
import { Button } from "../components/Button"
import { OfficialAnswer, QuestionType, QuestionWithAnswer, Category } from "../utils/types"
import "../styles/QuizPage.css";

export const QuizPage = () => {
    const [error, setError] = useState('')
    const api = new Api();
    const navigate = useNavigate();
    const [quizId, setQuizId] = useState();
    const [questions, setQuestions] = useState<QuestionType[]>([]);
    const [questionsWithAnswers, setQuestionsWithAnswers] = useState<(QuestionWithAnswer | null)[]>([]);
    const [result, setResult] = useState<OfficialAnswer[]>([])
    const [summary, setSummary] = useState('');
    const [passed, setPassed] = useState(false);
    const [categoryTitle, setCategoryTitle] = useState('');
    const { categoryId } = useParams();

    useEffect(() => {
        const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
        if (!sessionStorageItem) {
            navigate("/login")
            return
        }
        const token = JSON.parse(sessionStorageItem as string)['token']

        async function getCategories() {
            const [result, status] = await api.getAllCategories({ token })
            if (status !== HttpStatusCode.Ok) {
                setError(result['error'] || 'Es ist ein Fehler aufgetreten.')
                return undefined
            }
            setError('')
            const category = result['kategorien'].find((cat: Category) => cat.kategorie_id.toString() == categoryId)
            if (category) {
                setCategoryTitle(category.kategorie_name)
            }
        }

        getCategories();
    }, [])

    useEffect(() => {
        async function getQuiz() {
            const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
            if (!sessionStorageItem || !categoryId || !parseInt(categoryId)) {
                navigate("/login")
                return
            }
            const token = JSON.parse(sessionStorageItem as string)['token']
            const categoryInt: number = parseInt(categoryId);

            const [response, status] = await api.getQuizForCategory({ token, categoryId: categoryInt })
            if (status !== HttpStatusCode.Ok) {
                setError(response['error'] || 'Es ist ein Fehler aufgetreten.')
                setTimeout(() => setError(''), 3000)
            } else {
                setQuestions(response['fragen'])
                setQuestionsWithAnswers(new Array(response['fragen'].length).fill(null))
                setQuizId(response['quiz_id'])
            }
        }

        getQuiz()
    }, [])

    const onFinishQuiz = async () => {
        const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
        if (!sessionStorageItem || !categoryId) {
            navigate("/login")
            return
        }
        const token = JSON.parse(sessionStorageItem as string)['token']

        if (!quizId) {
            setError("Es ist ein Fehler aufgetreten. Versuche später nochmal.")
            setTimeout(() => setError(''), 3000)
            return
        }

        if (questionsWithAnswers.some(q => q === null || q?.gewaehlte_antwort === null)) {
            setError("Du hast noch nicht alle Fragen beantwortet!")
            return
        }

        const [response, status] = await api.postQuizAnswers({ 
            token, 
            quizId, 
            questions: questionsWithAnswers as QuestionWithAnswer[] 
        })
        if (status !== HttpStatusCode.Ok) {
            setError(response['error'] || 'Es ist ein Fehler aufgetreten.')
            setTimeout(() => setError(''), 3000)
        } else {
            setResult(response['auswertung'])
            const pointsGot = response['punktzahl']
            const maxPoints = questionsWithAnswers.length * 2
            const percentage = pointsGot / maxPoints
            const percentageRounded = Math.round(percentage * 1000) / 10
            setSummary(`${pointsGot}/${maxPoints} (${percentageRounded}%)`)
            setPassed(percentage > 0.5)
            window.scrollTo(0, 0)
        }
    }

    if (!quizId && questions.length === 0) {
        return null;
    }

    return (
        <div className="quiz-page" id="page-container">
            <h1 className="category-title">{categoryTitle}</h1>
            {error && <p className="quizpage-error">{error}</p>}
            {summary && <p className={`quiz-result ${passed ? 'quiz-passed' : 'quiz-not-passed'}`}>Ergebnis: {summary}</p>}
            <div className="content">
                {questions.map((question, index) => (
                    <QuestionWithAnswers 
                        onChange={(chosenAnswer: any, tipsUsed: boolean, qIndex: number): void => {
                            const copyQuestionsWithAnswers = [...questionsWithAnswers]
                            copyQuestionsWithAnswers[qIndex] = {
                                frage_id: question.frage_id, 
                                gewaehlte_antwort: chosenAnswer, 
                                tipp_benutzt: tipsUsed
                            }
                            setQuestionsWithAnswers(copyQuestionsWithAnswers)
                        }}
                        questionIndex={index}
                        questionId={question.frage_id}
                        question={question.frage_inhalt}
                        answerA={question.antworten[0]}
                        answerB={question.antworten[1]}
                        answerC={question.antworten[2]}
                        answerD={question.antworten[3]}
                        key={index} 
                        result={result.find(r => r.frage_id === question.frage_id)}
                    />
                ))}
                {summary 
                ? (<Button type="primary" label="Schließen" onClick={() => navigate("/")} />)
                : (
                <Button 
                    type="primary" 
                    label="Quiz abschließen" 
                    onClick={onFinishQuiz} 
                    disabled={questionsWithAnswers.some(q => q === null || q?.gewaehlte_antwort === null)} 
                />
                )}
            </div>
        </div>
    )
}