
import '../styles/Button.css'
import { ButtonProps } from "../utils/types"

export const Button = ({ label, onClick, type, className, disabled }: ButtonProps) => {
    return (
        <button onClick={onClick} disabled={disabled} className={`button button__${type} ${className ? className : ''}`}>
            {label}
        </button>
    )
}