
import '../styles/Textarea.css'
import { TextareaProps } from '../utils/types'

export const Textarea = ({name, value, onChange}: TextareaProps) => {
    return (
        <div className='textarea'>
            <p className='label'>{name}</p>
            <textarea name={name} value={value} onChange={onChange}/>
        </div>
    )
}