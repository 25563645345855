import { QuestionBody, User } from "../utils/types";
import { QuestionWithAnswer } from '../utils/types';

const isProduction = process.env.NODE_ENV === 'production';
const BASE_URL = isProduction ? '/api' : 'http://localhost:5000';


export class Api {
    register = async (username: string, password: string) => {
        const response = await fetch(BASE_URL + '/register', { 
            method: 'post', 
            mode: 'cors',
            body: JSON.stringify({
                username: username,
                passwort: password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    login = async (username: string, password: string) => {
        const response = await fetch(BASE_URL + '/login', { 
            method: 'post', 
            mode: 'cors',
            body: JSON.stringify({
                username: username,
                passwort: password
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    user = async ({ token, userId }: { token: string, userId: number}) => {
        const response = await fetch(BASE_URL + '/benutzer', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return responseObj['benutzer']?.find((user: User) => user?.id === userId)
    }

    getAllCategories = async ({ token }: { token: string}) => {
        const response = await fetch(BASE_URL + '/kategorien', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    postCategory = async ({ token, categoryName }: { token: string, categoryName: string }) => {
        const response = await fetch(BASE_URL + '/kategorien', {
            method: 'post',
            mode: 'cors',
            body: JSON.stringify({
                kategorie_name: categoryName
            }),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    getQuestion = async ({ token, questionId }: { token: string, questionId: number }) => {
        const response = await fetch(BASE_URL + '/frage/' + questionId, {
            method: 'get',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    getAllQuestions = async ({ token }: { token: string }) => {
        const response = await fetch(BASE_URL + '/fragen', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    getQuestionsByCategory = async ({ token, categoryId }: { token: string, categoryId: number }) => {
        const response = await fetch(BASE_URL + '/fragen_nach_kategorie/' + categoryId, {
            method: 'get',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    postQuestion = async ({ token, question }: { token: string, question: QuestionBody }) => {
        const response = await fetch(BASE_URL + '/fragen', {
            method: 'post',
            mode: 'cors',
            body: JSON.stringify({
                frage_id: question.id || null,
                frage_inhalt: question.questionContent,
                antworten: question.answers,
                kategorien: question.categories

            }),
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    getTipsForQuestion = async ({ token, questionId }: { token: string, questionId: number }) => {
        const response = await fetch(BASE_URL + '/tipps/' + questionId, {
            method: 'get',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    postTipForQuestion = async ({ token, questionId, tip }: { token: string, questionId: number, tip: string }) => {
        const response = await fetch(BASE_URL + '/tipps', {
            method: 'post',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                frage_id: questionId,
                tipp_inhalt: tip

            }),
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    getQuizForCategory = async ({ token, categoryId }: { token: string, categoryId: number}) => {
        const response = await fetch(BASE_URL + '/quiz/' + categoryId, {
            method: 'get',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    } 

    postQuizAnswers = async ({ token, quizId, questions}: { token: string, quizId: number, questions: QuestionWithAnswer[]}) => {
        const response = await fetch(BASE_URL + '/quiz', {
            method: 'post',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                quiz_id: quizId,
                fragen: questions

            }),
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    }

    getRanking = async ({ token }: { token: string }) => {
        const response = await fetch(BASE_URL + '/ranking', {
            method: 'get',
            mode: 'cors',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }
        })
        const responseObj = await response.json();
        return [responseObj, response.status];
    } 
}