import { useNavigate } from "react-router-dom";
import { checkAuth } from "../utils/checkAuth";
import { useEffect } from "react";

export const ProtectedRoute = ({ children }: { children: JSX.Element }): JSX.Element => {
    const navigate = useNavigate()

    useEffect(() => {
        async function getUser() {
            const user = await checkAuth()
            if (!user) {
                navigate("/login")
            }
        }
        getUser();
    }, [])

  return children;
};