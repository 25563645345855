import { useCallback, useEffect, useState } from "react"
import "../styles/Login.css"
import { Api } from "../api/Api"
import { Link, useNavigate } from "react-router-dom"
import { checkAuth } from "../utils/checkAuth"
import { Button } from "../components/Button"
import { Input } from "../components/Input"
import { HttpStatusCode } from "axios"

export const Login = () => {
    const [error, setError] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const api = new Api()
    const navigate = useNavigate()

    useEffect(() => {
        async function getUser() {
            const user = await checkAuth()
            if (user) {
                navigate("/")
            }
        }
        getUser();
    }, [])

    const onLoginClick = useCallback(async () => {
        const [loginData, status] = await api.login(name, password)
        if (status === HttpStatusCode.Ok) {
            window.sessionStorage.setItem('LOGGED_IN_USER', JSON.stringify({token: loginData['access_token'], userId: loginData['id']}))
            navigate("/")
        } else if (loginData.error) {
            setError(loginData.error)
        } else {
            setError("Ein Fehler ist aufgetreten")
        }
    }, [name, password])

    return (
        <div className="login-page">
            <h1>Login</h1>
            <div className="login-container">
                <Input name="username" type="text" value={name} onChange={e => setName(e.target.value)} />
                <Input name="passwort" type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                <div>
                    <Button type="secondary" onClick={onLoginClick} label="Einloggen" />
                    <p className="helper-text">Hast du noch kein Konto? <Link to="/register">Jetzt registrieren</Link></p>
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    )
}