import { Api } from "../api/Api";

export const checkAuth = async () => {
    const loginData = window.sessionStorage.getItem('LOGGED_IN_USER');
    if (!loginData) {
        return undefined;
    }

    const api = new Api()
    const user = await api.user(JSON.parse(loginData))
    return user
  };