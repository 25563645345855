import { useEffect, useState } from "react"
import "../styles/QuestionWithAnswers.css"
import { ArrowUp } from "../static/icons/ArrowTop"
import { ArrowDown } from "../static/icons/ArrowDown"
import { useNavigate } from "react-router-dom"
import { Api } from "../api/Api"
import { QuestionWithAnswersProps, Tip } from "../utils/types"

export const QuestionWithAnswers = ({
    onChange,
    questionId,
    question,
    answerA,
    answerB,
    answerC,
    answerD,
    questionIndex,
    result
}: QuestionWithAnswersProps) => {
    const [tipsShown, setTipsShown] = useState(false)
    const [usedTips, setUsedTips] = useState(false)
    const [chosenAnswer, setChosenAnswer] = useState(null)
    const [tips, setTips] = useState<Tip[]>([]);
    const navigate = useNavigate();
    const api = new Api();

    const answers = [answerA, answerB, answerC, answerD]

    useEffect(() => {
        async function getTips() {
            const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
            if (!sessionStorageItem) {
                navigate("/login")
                return
            }
            const token = JSON.parse(sessionStorageItem as string)['token']

            const [response, status] = await api.getTipsForQuestion({ token, questionId })
            if (status !== 200) {
                setTips(response['error'] || 'Es ist ein Fehler aufgetreten.')
            } else {
                setTips(response['tipps'])
            }
        }

        getTips()
    }, [])

    return (
        <div className="question-container">
            <p className="title">{question}</p>
            <form onChange={(e: any) => {
                setChosenAnswer(e.target.value)
                onChange(e.target.value, usedTips, questionIndex)
            }}>
                {answers.map(answer => (
                    <div className={`answer ${
                        (result && result?.korrekte_antwort.toString() === answer.antwort_id.toString()) ? 'answer-correct' 
                        : (result && result?.gewaehlte_antwort.toString() === answer.antwort_id.toString() && !result?.ist_richtig) ? 'answer-incorrect' 
                        : ''}`}>
                        <input 
                            type="radio" 
                            name={question} 
                            value={answer.antwort_id} 
                            disabled={!!result}
                        />
                        <p>{answer.antwort_inhalt}</p>
                        {result && result?.korrekte_antwort === answer.antwort_id && result?.ist_richtig && result?.tipp_benutzt && <p>+1</p>}
                        {result && result?.korrekte_antwort === answer.antwort_id && result?.ist_richtig && !result?.tipp_benutzt && <p>+2</p>}
                    </div>
                ))}
            </form>
            {tips && tips.length > 0 && (
                <>
                    <div className="tip-header">
                        <p>Sieh dir die Erklärungen deiner Kollegen an</p>
                        {tipsShown ? (
                            <div className="tip-array" onClick={() => setTipsShown(false)}>
                                <ArrowUp fillColor="#9765D6" />
                            </div>
                        ):(
                            <div className="tip-array" onClick={e => {
                                e.preventDefault();
                                setTipsShown(true)
                                setUsedTips(true)
                                onChange(chosenAnswer, true, questionIndex)
                            }}>
                                <ArrowDown fillColor="#9765D6" />
                            </div>
                        )}
                    </div>
                    {tipsShown && tips.map(tip => (
                        <div className="tip">
                            <p>{tip.tipp_inhalt}</p>
                            <p>___</p>
                        </div>
                    ))}
                </>
            )}
        </div>
    )
}