import { Link, useLocation, useNavigate } from "react-router-dom";
import '../styles/MainNav.css'
import { House } from "../static/icons/House";
import { Question } from "../static/icons/Question";
import { Ranking } from "../static/icons/Ranking";
import { Logout } from "../static/icons/Logout";
import { Login } from "../static/icons/Login";
import { useCallback, useEffect, useState } from "react";
import { checkAuth } from "../utils/checkAuth";

export const MainNav = () => {
    const location = useLocation()
    const [loggedIn, setLoggedIn] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        async function getUser() {
            const user = await checkAuth()
            if (user) {
                setLoggedIn(true)
            }
        }
        getUser();
    }, [location.pathname])

    const onLogout = useCallback(() => {
        window.sessionStorage.removeItem('LOGGED_IN_USER')
        setLoggedIn(false)
        navigate("/login")
    }, [])

    return (
        <nav className="main-nav">
            <ul>
                <li>
                    <Link to="/" aria-label="homepage">
                        <House className="nav-icon nav-icon__house" fillColor={location.pathname === "/" ? '#fff' : '#bebebe'} />
                    </Link>
                </li>
                <li>
                    <Link to="/questions">
                        <Question className="nav-icon nav-icon__questions" fillColor={location.pathname.startsWith("/questions") ? '#fff' : '#bebebe'} />
                    </Link>
                </li>
                <li>
                    <Link to="/ranking">
                        <Ranking className="nav-icon nav-icon__ranking" fillColor={location.pathname === "/ranking" ? '#fff' : '#bebebe'} />
                    </Link>
                </li>
            </ul>
            <ul>
                <li>
                    {loggedIn ? (
                        <button onClick={onLogout} className="nav-button__logout">
                            <Logout className="nav-icon nav-icon__logout" fillColor='#bebebe' />
                        </button>
                    ) : (
                        <Link to="/login">
                            <Login className="nav-icon nav-icon__login" fillColor={location.pathname === "/login" ? '#fff' : '#bebebe'} />
                        </Link>
                    )}
                </li>
            </ul>
        </nav>
    )
}