
import '../styles/Dropdown.css'
import { DropdownProps } from '../utils/types'

export const Dropdown = ({name, value, onChange, children, noLabel, className}: DropdownProps) => {
    return (
        <div className={`dropdown ${className ? className : ''}`}>
            {!noLabel && <p className='label'>{name}</p>}
            <select name={name} className="dropdown-select" value={value} onChange={onChange}>
                {children}
            </select>
        </div>
    )
}