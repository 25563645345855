
import '../styles/Input.css'
import { InputProps } from '../utils/types'

export const Input = ({name, type, value, onChange, className}: InputProps) => {
    return (
        <div className={`input ${className ? className : ''}`}>
            <p className='label'>{name}</p>
            <input name={name} type={type} value={value} onChange={onChange}/>
        </div>
    )
}