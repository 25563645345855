import "../styles/RankingItem.css"
import { RankingItemProps } from "../utils/types"

export const RankingItem = ({ nr, name, points }: RankingItemProps) => {
    return (
        <div 
            className={`ranking-item-container ${
                nr === 1 ? 'number-one' 
                : nr === 2 ? 'number-two' 
                : nr === 3 ? 'number-three' 
                : ''}`
            }>
            <div className="ranking-item-container-name">
                <p className="ranking-item-nr">{nr}.</p> 
                <p className="ranking-item-name">{name}</p>
            </div>
            <p className="ranking-item-points">{points} Pkt.</p>
        </div>
    )
}