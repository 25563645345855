import React, { useEffect, useState } from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { EditQuestionPage } from './pages/EditQuestionPage';
import { Homepage } from './pages/Homepage';
import { Login } from './pages/Login';
import { QuestionsPage } from './pages/QuestionsPage';
import { QuizPage } from './pages/QuizPage';
import { RankingPage } from './pages/RankingPage';
import { Register } from './pages/Register';
import Layout from './pages/Layout';
import { ProtectedRoute } from './components/ProtectedRoute';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ProtectedRoute><Homepage /></ProtectedRoute>} />
          <Route path="questions" element={<ProtectedRoute><QuestionsPage /></ProtectedRoute>} />
          <Route path="ranking" element={<ProtectedRoute><RankingPage /></ProtectedRoute>} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="questions/:questionId" element={<ProtectedRoute><EditQuestionPage /></ProtectedRoute>} />
          <Route path="questions/new" element={<ProtectedRoute><EditQuestionPage /></ProtectedRoute>} />
          <Route path="quiz/category/:categoryId" element={<ProtectedRoute><QuizPage /></ProtectedRoute>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
