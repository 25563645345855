import { useCallback, useEffect, useState } from "react"
import "../styles/Register.css"
import { Api } from "../api/Api"
import { Link, useNavigate } from "react-router-dom"
import { checkAuth } from "../utils/checkAuth"
import { Button } from "../components/Button"
import { Input } from "../components/Input"
import { HttpStatusCode } from "axios"

export const Register = () => {
    const [error, setError] = useState('')
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [repeatedPassword, setRepeatedPassword] = useState('')
    const api = new Api()
    const navigate = useNavigate()

    useEffect(() => {
        async function getUser() {
            const user = await checkAuth()
            if (user) {
                navigate("/")
            }
        }
        getUser();
    }, [])

    const onRegisterClick = useCallback(async () => {

        if (password === repeatedPassword) {
            const [registerData, status] = await api.register(name, password)
        
            if (status === HttpStatusCode.Created) {
                window.sessionStorage.setItem('LOGGED_IN_USER', JSON.stringify({token: registerData['access_token'], userId: registerData['id']}))
                navigate("/")
            } else if (registerData.error) {
                setError(registerData.error)
            } else {
                setError("Ein Fehler ist aufgetreten")
            }
        } else {
            setError("Die angegebenen Passwörter stimmen nicht überein")
        }
        
    }, [name, password, repeatedPassword])

    return (
        <div className="register-page">
            <h1>Registrierung</h1>
            <div className="register-container">
                <Input name="username" type="text" value={name} onChange={e => setName(e.target.value)}/>
                <Input name="passwort" type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                <Input name="Passwort wiederholen" type="password" value={repeatedPassword} onChange={e => setRepeatedPassword(e.target.value)}/>
                <div>
                    <Button type="secondary" onClick={onRegisterClick} label="Registrieren" />
                    <p className="helper-text">Hast du bereits ein Konto? <Link to="/login">Jetzt einloggen</Link></p>
                </div>
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    )
}