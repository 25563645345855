import { useCallback, useEffect, useMemo, useState } from "react"
import { Input } from "../components/Input"
import { Textarea } from "../components/Textarea"
import { Dropdown } from "../components/Dropdown"
import "../styles/EditQuestionPage.css"
import { Button } from "../components/Button"
import { useNavigate, useParams } from "react-router-dom";
import { Api } from "../api/Api"
import { HttpStatusCode } from "axios"
import { Answer, Category, Tip, answerState, answersArray } from "../utils/types"

export const EditQuestionPage = () => {
    const api = new Api()
    const [question, setQuestion] = useState('')
    const [answerA, setAnswerA] = useState<Answer | undefined>()
    const [answerB, setAnswerB] = useState<Answer | undefined>()
    const [answerC, setAnswerC] = useState<Answer | undefined>()
    const [answerD, setAnswerD] = useState<Answer | undefined>()
    const [feedback, setFeedback] = useState('')
    const [newTip, setNewTip] = useState('')
    const [categories, setCategories] = useState<Category[]>()
    const [checkedCategories, setCheckedCategories] = useState<number[]>([])
    const [error, setError] = useState('')
    const [tips, setTips] = useState<Tip[]>([])
    const { questionId } = useParams()
    const navigate = useNavigate()
    const answers: answersArray = [
        {letter: 'A', state: answerA, setState: setAnswerA}, 
        {letter: 'B', state: answerB, setState: setAnswerB},
        {letter: 'C', state: answerC, setState: setAnswerC},
        {letter: 'D', state: answerD, setState: setAnswerD}
    ]

    const setCorrectAnswer = useCallback((value: string) => {
        answers.forEach((answer: answerState) => {
            if (answer.letter === value) {
                answer.setState({ antwort: answer.state?.antwort || '', korrekt: true })
            } else {
                answer.setState({ antwort: answer.state?.antwort || '', korrekt: false })
            }
        })

    }, [answerA, answerB, answerC, answerD])

    useEffect(() => {
        async function getQuestion() {
            if (questionId !== 'new' && questionId) {
                const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
                if (!sessionStorageItem) {
                    navigate("/login")
                    return
                }
                const token = JSON.parse(sessionStorageItem as string)['token']
                const questionInt: number = parseInt(questionId);
                const [result, status] = await api.getQuestion({ token, questionId: questionInt })
    
                if (status === HttpStatusCode.Ok) {
                    setQuestion(result['inhalt'])
                    result['antworten']?.forEach((antwort: Answer, index: number) => {
                        answers[index].setState(antwort)
                    })
                    setCheckedCategories(result['kategorien'].map((c: Category) => c.kategorie_id))         
                }
                else {
                    setError(result['error'] ? result['error'] : 'Es gab einen Fehler')
                    setTimeout(() => setError(''), 3000)
                }
            }
        }

        getQuestion()
    }, [])

    useEffect(() => {
        async function getAllCategories() {
            const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
            if (!sessionStorageItem) {
                navigate("/login")
                return
            }
            const token = JSON.parse(sessionStorageItem as string)['token']
            const [result, status] = await api.getAllCategories({ token })

            if (status === HttpStatusCode.Ok) {
                setCategories(result['kategorien'])
            }
            else {
                setError(result['error'] ? result['error'] : 'Es gab einen Fehler')
                setTimeout(() => setError(''), 3000)
            }
        }

        getAllCategories()
    }, [])

    useEffect(() => {
        async function getTips() {
            if (questionId !== 'new' && questionId) {
                const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
                if (!sessionStorageItem) {
                    navigate("/login")
                    return
                }
                const token = JSON.parse(sessionStorageItem as string)['token']
                const questionInt: number = parseInt(questionId);
                const [result, status] = await api.getTipsForQuestion({ token, questionId: questionInt })
    
                if (status === HttpStatusCode.Ok) {
                    setTips(result['tipps'])
                }
                else {
                    setError(result['error'] ? result['error'] : 'Es gab einen Fehler')
                    setTimeout(() => setError(''), 3000)
                }
            }
        }

        getTips()
    }, [])

    const correctAnswer = useMemo(() => {
        const correct = answers.find(a => a.state?.korrekt === true)
        if (!correct) {
            setAnswerA({ korrekt: true, antwort: answerA?.antwort || '' })
            return 'A'
        }
        return correct?.letter
    }, [answerA, answerB, answerC, answerD])

    const saveQuestion = useCallback(async () => {
        const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
        if (!sessionStorageItem) {
            navigate("/login")
            return
        }
        const token = JSON.parse(sessionStorageItem as string)['token']

        if (answerA && answerB && answerC && answerD) {
            const questionBody = {
                id: questionId !== 'new' && typeof questionId === 'string' ?  parseInt(questionId): undefined,
                questionContent: question,
                answers: [answerA, answerB, answerC, answerD],
                categories: checkedCategories.map(c => {return { kategorie_id: c }})
            }
    
            const [result, status] = await api.postQuestion({ token, question: questionBody })
            if (status !== HttpStatusCode.Created) {
                setError(result['error'])
                setTimeout(() => setError(''), 3000)
            } else {
                setFeedback('Frage gespeichert')
                setTimeout(() => setFeedback(''), 3000)
                navigate("/questions/" + result['frage_id'])
            }
        }

        async function getQuestion() {
            if (questionId !== 'new' && questionId) {
                const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
                if (!sessionStorageItem) {
                    navigate("/login")
                    return
                }
                const token = JSON.parse(sessionStorageItem as string)['token']
                const questionInt: number = parseInt(questionId);
                const [result, status] = await api.getQuestion({ token, questionId: questionInt })
    
                if (status === HttpStatusCode.Ok) {
                    setQuestion(result['inhalt'])
                    result['antworten']?.forEach((antwort: Answer, index: number) => {
                        answers[index].setState(antwort)
                    })
                    setCheckedCategories(result['kategorien'].map((c: Category) => c.kategorie_id))
                }
                else {
                    setError(result['error'] ? result['error'] : 'Es gab einen Fehler')
                    setTimeout(() => setError(''), 3000)
                }
            }
        }

        getQuestion()

    }, [answerA, answerB, answerC, answerD, question, checkedCategories, error])

    const saveTip = useCallback(async () => {
        const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
        if (!sessionStorageItem) {
            navigate("/login")
            return
        }
        const token = JSON.parse(sessionStorageItem as string)['token']

        if (newTip && questionId) {
            const questionInt: number = parseInt(questionId);
            const [result, status] = await api.postTipForQuestion({ token, questionId: questionInt, tip: newTip })
            if (status !== HttpStatusCode.Created) {
                setError(result['error'])
                setTimeout(() => setError(''), 3000)
            } else {
                setFeedback('Frage gespeichert')
                setNewTip('')
                setTimeout(() => { setFeedback('')}, 3000)
            }
        }

        async function getTips() {
            if (questionId !== 'new' && questionId) {
                const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
                if (!sessionStorageItem) {
                    navigate("/login")
                    return
                }
                const token = JSON.parse(sessionStorageItem as string)['token']
                const questionInt: number = parseInt(questionId);
                const [result, status] = await api.getTipsForQuestion({ token, questionId: questionInt })
    
                if (status === HttpStatusCode.Ok) {
                    setTips(result['tipps'])
                }
                else {
                    setError(result['error'] ? result['error'] : 'Es gab einen Fehler')
                    setTimeout(() => setError(''), 3000)
                }
            }
        }

        getTips()
    }, [newTip, questionId, error])

    const onCategoryClick = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const categoryId = parseInt(e.target.value);
    
        if (e.target.checked) {
          setCheckedCategories((prevCheckedCategories) => [...prevCheckedCategories, categoryId]);
        } else {
          setCheckedCategories((prevCheckedCategories) =>
            prevCheckedCategories.filter((id) => id !== categoryId)
          );
        }
      }, [checkedCategories]);

    return (
        <div id="page-container" className="page-container-edit-question">
            {error && <p className="edit-question-error">{error}</p>}
            <Textarea name="Frage" value={question} onChange={e => setQuestion(e.target.value)} />
            <Input className="answer-input" type="text" name="A" value={answerA?.antwort} onChange={e => setAnswerA({ korrekt: !!answerA?.korrekt, antwort: e.target.value})} />
            <Input className="answer-input" type="text" name="B" value={answerB?.antwort} onChange={e => setAnswerB({ korrekt: !!answerB?.korrekt, antwort: e.target.value })} />
            <Input className="answer-input" type="text" name="C" value={answerC?.antwort} onChange={e => setAnswerC({ korrekt: !!answerC?.korrekt, antwort: e.target.value })} />
            <Input className="answer-input" type="text" name="D" value={answerD?.antwort} onChange={e => setAnswerD({ korrekt: !!answerD?.korrekt, antwort: e.target.value })} />
            <Dropdown name="korrekte Antwort" value={correctAnswer} onChange={e => setCorrectAnswer(e.target.value)}>
                <option value="A" selected={correctAnswer === 'A'}>{answerA?.antwort}</option>
                <option value="B" selected={correctAnswer === 'B'}>{answerB?.antwort}</option>
                <option value="C" selected={correctAnswer === 'C'}>{answerC?.antwort}</option>
                <option value="D" selected={correctAnswer === 'D'}>{answerD?.antwort}</option>
            </Dropdown>
            <div className="question-page-categories-container">
                <p className="question-page-categories-title">Kategorien</p>
                {categories?.map(cat => (
                    <div className="question-page-category-container" key={cat.kategorie_id}>
                    <input
                        type="checkbox"
                        name={cat.kategorie_name}
                        value={cat.kategorie_id}
                        checked={checkedCategories?.includes(cat.kategorie_id)}
                        onChange={onCategoryClick}
                    />
                    <p>{cat.kategorie_name}</p>
                    </div>
                ))}
            </div>
            <div className="tips">
            <h3 className="tips-title">Hinweise</h3>
            {(!questionId || questionId === 'new') && (
                <p className="add-tips-not-active">Um Hinweise hinzufügen, muss die Frage zuerst gespeichert werden.</p>
            )}
            {tips?.map(tip => (
                <div className="tip">
                    <p>{tip.tipp_inhalt}</p>
                    <p className="tip-divider">___</p>
                </div>
            ))}
            <Textarea name="Neuer Hinweis" value={newTip} onChange={e => setNewTip(e.target.value)} />
            <Button
                type="secondary" 
                onClick={saveTip} 
                label="Hinweis hinzufügen" 
                className="add-tip-btn" 
                disabled={!questionId || questionId === 'new'}
            />
            </div>
            {error && <p className="edit-question-error">{error}</p>}
            {feedback && <p>{feedback}</p>}
            <Button type="primary" onClick={saveQuestion} label="Speichern" className="save-question" />
        </div>
    )
}