import { Button } from "./Button"
import "../styles/Question.css"
import { useNavigate } from "react-router-dom"

export const Question = ({ title, id }: { title: string, id: number }) => {
    const navigate = useNavigate()

    return (
        <div className="question-container">
            <p>{title}</p>
            <Button 
                type="primary" 
                label="Frage bearbeiten" 
                onClick={() => navigate(`/questions/${id}`)} 
            />
        </div>
    )
}