import { Button } from "./Button"
import "../styles/Category.css"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { CategoryComponentProps } from "../utils/types"


export const Category = ({ title, id, questions_count }: CategoryComponentProps) => {
    const navigate = useNavigate();

    const onInitializeQuiz = useCallback(() => {
        const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
        if (!sessionStorageItem) {
            navigate("/login")
            return
        }

        navigate("/quiz/category/" + id)
    }, [id])

    return (
        <div className="category-container">
            <p className="title">{title}</p>
            <p className="questions-count">{questions_count} Frage{questions_count === 1 ? '' : 'n'}</p>
            <Button type="primary" label="Quiz starten" onClick={onInitializeQuiz} disabled={questions_count < 1} />
        </div>
    )
}