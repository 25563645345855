import { useNavigate } from "react-router-dom"
import { Button } from "../components/Button"
import { Question } from "../components/Question"
import "../styles/QuestionsPage.css"
import { Dropdown } from "../components/Dropdown"
import { Api } from "../api/Api"
import { useEffect, useState } from "react"
import { HttpStatusCode } from "axios"
import { Category, QuestionType } from "../utils/types"

export const QuestionsPage = () => {
    const [questions, setQuestions] = useState<QuestionType[] | undefined>()
    const [error, setError] = useState()
    const [categories, setCategories] = useState<Category[] | undefined>()
    const [chosenCategory, setChosenCategory] = useState(0)
    const api = new Api()
    const navigate = useNavigate()

    useEffect(() => {
        async function getQuestions() {
            const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
            if (!sessionStorageItem) {
                navigate("/login")
                return
            }
            const token = JSON.parse(sessionStorageItem as string)['token']
            let questions;
            let status;
            if (chosenCategory) {
                [questions, status] = await api.getQuestionsByCategory({ token, categoryId: chosenCategory })
            } else {
                [questions, status] = await api.getAllQuestions({ token })
            }
            if (status !== HttpStatusCode.Ok) {
                setError(questions['msg'])
                return undefined
            }
            setError(undefined)
            setQuestions(questions['fragen'])
        }

        getQuestions();
    }, [chosenCategory])

    useEffect(() => {
        async function getCategories() {
            const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
            if (!sessionStorageItem) {
                navigate("/login")
                return
            }
            const token = JSON.parse(sessionStorageItem as string)['token']
            const [categories, status] = await api.getAllCategories({ token })
            if (status !== HttpStatusCode.Ok) {
                setError(categories['msg'])
                return undefined
            }
            setError(undefined)
            setCategories(categories['kategorien'])
        }

        getCategories();
    }, [])

    if (error) {
        <div>Es ist ein Fehler aufgetreten: {error}</div>
    }

    return (
        <div className="questions-page" id="page-container">
            <Button 
                label="Neue Frage erstellen" 
                type="primary" 
                onClick={() => navigate("/questions/new")} 
            />
            <hr className="divider" />
            <div className="header">
                <h1>Alle Fragen</h1>
                <Dropdown name="categories" className="dropdown-categories" value={chosenCategory} onChange={e => setChosenCategory(e.target.value)} noLabel>
                    <option value='' selected>Alle Kategorien...</option>
                    {categories?.map(category => (
                        <option value={category.kategorie_id}>{category.kategorie_name}</option>
                    ))}
                </Dropdown>
            </div>
            <div className="content">
                {questions?.map(question => (
                    <Question key={question.frage_id} title={question.frage_inhalt} id={question.frage_id} />
                ))}
            </div>
        </div>
    )
}