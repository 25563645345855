import { useEffect, useState } from "react"
import { RankingItem } from "../components/RankingItem"
import "../styles/RankingPage.css"
import { useNavigate } from "react-router-dom"
import { Api } from "../api/Api"
import { HttpStatusCode } from "axios"
import { RankingItemType } from "../utils/types"

export const RankingPage = () => {
    const navigate = useNavigate();
    const api = new Api();
    const [error, setError] = useState('');
    const [rankingItems, setRankingItems] = useState<RankingItemType[]>([]);

    useEffect(() => {
        const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
        if (!sessionStorageItem) {
            navigate("/login")
            return
        }
        const token = JSON.parse(sessionStorageItem as string)['token']

        async function getCategories() {
            const [result, status] = await api.getRanking({ token })
            if (status !== HttpStatusCode.Ok) {
                setError(result['error'] || 'Es ist ein Fehler aufgetreten.')
                return undefined
            }
            setError('')
            const ranking = result['benutzer_mit_punkten'];
            const rankingSorted = ranking.sort((item1: RankingItemType, item2: RankingItemType) => item2.benutzer_punktzahl - item1.benutzer_punktzahl)
            setRankingItems(rankingSorted);
        }

        getCategories();
    }, [])

    return (
        <div id="page-container" className="ranking-page-container">
            <h1>Ranking</h1>
            {error && <p className="ranking-error">{error}</p>}
            {rankingItems.map((item, index) => (
                <RankingItem
                    key={item.benutzer_id}
                    nr={index + 1}
                    name={item.benutzer_username}
                    points={item.benutzer_punktzahl}
                />
            ))}
        </div>
    )
}