import { useEffect, useState } from "react"
import { Category } from "../components/Category"
import "../styles/Homepage.css"
import { Api } from "../api/Api"
import { useNavigate } from "react-router-dom"
import { HttpStatusCode } from "axios"
import { CategoryWithQuestionsCount } from "../utils/types"

export const Homepage = () => {
    const [categories, setCategories] = useState<CategoryWithQuestionsCount[]>([])
    const [error, setError] = useState('')
    const api = new Api()
    const navigate = useNavigate()

    useEffect(() => {
        const sessionStorageItem = window.sessionStorage.getItem('LOGGED_IN_USER')
        if (!sessionStorageItem) {
            navigate("/login")
            return
        }
        const token = JSON.parse(sessionStorageItem as string)['token']

        async function getCategories() {
            const [result, status] = await api.getAllCategories({ token })
            if (status !== HttpStatusCode.Ok) {
                setError(result['error'] || 'Es ist ein Fehler aufgetreten.')
                return undefined
            }
            setError('')
            setCategories(result['kategorien'])
        }

        getCategories();
    }, [])

    return (
        <div className="homepage" id="page-container">
            <h1>Alle Kategorien</h1>
            {error && <p className="homepage-error">{error}</p>}
            <div className="content">
                {categories.map(category => (
                    <Category 
                        key={category.kategorie_id} 
                        title={category.kategorie_name} 
                        id={category.kategorie_id} 
                        questions_count={category.anzahl_fragen || 0} 
                    />
                ))}
            </div>
        </div>
    )
}